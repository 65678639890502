var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('Object name'),"required":"","rules":[
            (v) => !!v || _vm.$t('Object name is required'),
            (v) =>
              v.length >= 2 || _vm.$t('Minimum required characters is') + ' 2',
          ],"validate-on-blur":""},model:{value:(_vm.newObjectName),callback:function ($$v) {_vm.newObjectName=$$v},expression:"newObjectName"}})],1),_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-text-field',{staticClass:"ml-lg-4 ml-xl-4",attrs:{"label":_vm.$t('Object title'),"required":"","rules":[
            (v) => !!v || _vm.$t('Title name is required'),
            (v) =>
              v.length >= 2 || _vm.$t('Minimum required characters is') + ' 2',
          ],"validate-on-blur":""},model:{value:(_vm.newObjectTitle),callback:function ($$v) {_vm.newObjectTitle=$$v},expression:"newObjectTitle"}})],1),_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-select',{staticClass:"mr-3 ml-lg-4 ml-xl-4",attrs:{"items":_vm.objectTypes,"label":_vm.$t('Select object type'),"menu-props":"bottom","required":"","rules":[(v) => !!v || _vm.$t('You must select an item')],"validate-on-blur":""},model:{value:(_vm.newObjectType),callback:function ($$v) {_vm.newObjectType=$$v},expression:"newObjectType"}})],1),_c('v-flex',{attrs:{"xs12":"","md3":""}},[(_vm.enableAdd)?_c('v-btn',{staticClass:"mt-3 dropdown-action-button edit-page-action-button add-page-element-btn",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" "+_vm._s(_vm.$t("Add item"))+" "),_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.enableRemove)?_c('v-btn',{staticClass:"mt-3",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.removeItem()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }